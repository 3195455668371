import React, { useEffect, useRef } from 'react';
import Helmet                       from 'react-helmet';
import Layout                       from '../components/Layout/Layout';
import { graphql }                  from 'gatsby';

export default ({ data }) => {
  const iframeRef = useRef(null);
  const documents = data.prismic.allDocumentss.edges[0].node;

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      // iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
    }
  });

  return (

      <>
        <Helmet title={'Privacy'} titleTemplate={`Assibro | %s`} meta={[
          {
            name: `description`,
            content: 'Assibro',
          },
          {
            property: `og:title`,
            content: 'Assibro',
          },
          {
            property: `og:description`,
            content: 'Assibro',
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: 'Assibro',
          },
          {
            name: `twitter:title`,
            content: 'Assibro',
          },
          {
            name: `twitter:description`,
            content: 'Assibro',
          },
        ]}/>
        {/*<Document options={{
          cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
          cMapPacked: true,
          workerSrc: '/pdf.worker.js',
        }} file={{url: documents.privacy_policy.url}}>
          <Page pageNumber={1}/>
        </Document>*/}
        <iframe style={{ width: '100%', minHeight: '100vh', display: 'block' }} src={documents.privacy_policy.url} ref={iframeRef}></iframe>
      </>
  );
}

export const query = graphql`
    {
        prismic {
            allMacrocategorys(sortBy: position_ASC) {
                edges {
                    node {
                        name
                        icon
                        _meta {
                            uid
                            id
                        }
                    }
                }
            }
            allDocumentss {
                edges {
                    node {
                        attachment_3_4 {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                        general_conditions {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                        privacy_policy {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                        electronic_signature {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;
